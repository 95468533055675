<template>
<!-- IP规划师培训报名 -->
  <div class="enroll-content">
    <div class="enroll-title">
      IP规划师培训报名
    </div>
    <el-steps class="enroll-steps" :class="'active'+active" :active="active" align-center>
      <el-step title="基本信息"></el-step>
      <el-step title="教育经历"></el-step>
      <el-step title="课程选择"></el-step>
    </el-steps>
    <el-form ref="form" :rules="rules" class="enroll-form" :model="form" label-position="top" label-width="80px">
      <div v-if="active==1">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="form.name" placeholder="请输入您的姓名" maxlength="10" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生日：" prop="birthdayData">
              <el-date-picker v-model="form.birthdayData" type="date" format="yyyy-MM-dd" value-format="timestamp"
              placeholder="选择日期" :picker-options="pickerBeginDateBefore"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="邮箱：" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号：" prop="telephone">
              <el-input v-model="form.telephone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="身份证号：" prop="idCard">
          <el-input v-model="form.idCard" placeholder="请输入您的身份证号" maxlength="18" show-word-limit></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="地址" prop="region">
              <el-cascader size="large" :options="regionData" v-model="form.region" @change="changeCity">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="详细地址："  prop="address">
              <el-input v-model="form.address" placeholder="请输入您的详细地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="active==2">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="学校：" prop="school">
              <el-input v-model="form.school" placeholder="请输入您的学校"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历：" prop="education">
              <el-select v-model="form.education" placeholder="请选择您的学历" @change="selectEdu">
                <el-option
                  v-for="item in educationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="毕业证或学生证：" prop="diplomaImage1" class="is-required">
          <div class="card-box">
            <el-upload
              class="card-uploader"
              :action="config.qnuploadurl"
              :show-file-list="false"
              :data="{token: qntoken}"
              :before-upload="beforeUpload"
              :on-success="uploadSuccess1"
              accept=".jpg,.jpeg,.png,.gif">
              <img v-if="form.diplomaImage1" :src="form.diplomaImage1" class="card-img">
              <i class="el-icon-upload avatar-uploader-icon">
                <p class="desc">上传证件（正面）</p>
              </i>
            </el-upload>
            <el-upload
              class="card-uploader"
              :action="config.qnuploadurl"
              :show-file-list="false"
              :data="{token: qntoken}"
              :before-upload="beforeUpload"
              :on-success="uploadSuccess2"
              accept=".jpg,.jpeg,.png,.gif">
              <img v-if="form.diplomaImage2" :src="form.diplomaImage2" class="card-img">
              <i class="el-icon-upload avatar-uploader-icon">
                <p class="desc">上传证件（背面）</p>
              </i>
            </el-upload>
            <div class="tips">图片格式支持jpg、png文件，大小不超过5M</div>
          </div>
        </el-form-item>
      </div>
      <div v-if="active==3">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="社会人士或在校学生：" prop="inSchool">
              <el-select v-model="form.inSchool" placeholder="请选择是否在校" @change="selectInSch();editValidate()">
                <el-option
                  v-for="item in inSchoolList"
                  :key="'ipType'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作年限：" prop="workYear">
              <el-select v-model="form.workYear" :disabled="form.inSchool==2" placeholder="请选择工作年限" @change="editValidate()">
                <el-option
                  v-for="item in workYearList"
                  :key="'workYear'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="课程选择：" prop="ipType">
              <el-select v-model="form.ipType" placeholder="请选择课程" @change="changeType">
                <el-option
                  v-for="(item,i) in ipTypeCourseList"
                  :key="'ipType'+item.ipType"
                  :label="item.courseName"
                  :value="item.ipType"
                  v-if="i < maxIpType">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="授课方式：">
              <el-select v-model="form.teachType" placeholder="请选择授课方式">
                <el-option
                  v-for="item in teachTypeList"
                  :key="'teachType'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="region-item">
          <el-col :span="12">
            <el-form-item label="报名点：" prop="provinceId" class="is-required">
              <el-select v-model="provinceId" placeholder="请选择报名点省份" @change="changeProvince">
                <el-option
                  v-for="item in provinceList"
                  :key="'province'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="  " prop="cityId">
              <el-select v-model="cityId" placeholder="请选择报名点市" @change="getCompanyList">
                <el-option
                  v-for="item in cityList"
                  :key="'city'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="companyId">
              <el-select v-model="form.companyId" placeholder="请选择报名点">
                <el-option
                  v-for="item in companyList"
                  :key="'city'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="论文地址：" prop="thesis_file1" v-if="paperShow && form.ipType==4">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input class="file-upload" v-model="form.thesis_file1" placeholder="论文一地址"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="file-upload" v-model="form.thesis_file2" placeholder="论文二地址"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <div class="discount-box">
          <!-- code -->
          <el-row :gutter="20">
            <el-form-item label="优惠码：" prop="code">
              <el-input v-model="form.code" @input="checkCode" placeholder="请在此处输入优惠码"></el-input>
              <el-button type="danger" :disabled="codeDisabled" class="btn-discount" @click="checkPromoCode">使用</el-button>
            </el-form-item>
          </el-row>
        </div>
      </div>
      <div class="money-box" v-if="active==3">
        费用：
        <div class="money"><i class="small">￥</i>{{price}}</div>
        <div class="txt">（此费用不包含考试费用）</div>
      </div>
      <div class="agreement" v-if="active==3">
        <el-checkbox v-model="agreeChecked"></el-checkbox>
        我已阅读并同意
        <div class="link" @click="onRoute('Agreement','FWXY')">《艺集学习服务协议》</div>和
        <div class="link" @click="onRoute('Agreement','CQBH')">《艺集学习知识产权保护协议》</div>
        </div>
    </el-form>
    <el-button style="margin-top: 12px;" type="primary" round @click="next" v-if="active<3">下一步</el-button>
    <el-button type="primary" v-preventReClick @click="onSubmit" :disabled="!agreeChecked" v-else>提交</el-button>
  </div>
</template>

<script>
import config from "@/config";
import { getToken } from '@/utils/auth';
import { regionData,CodeToText } from "element-china-area-data";
import { idCardValidity,phoneRules,checkEmail,checkURL} from "@/utils/rule.js"
export default {
  data() {
    const fileRule = (rule, value, callback) => {
      console.log(checkURL(this.form.thesis_file1))
      console.log(checkURL(this.form.thesis_file2))
      if (!checkURL(this.form.thesis_file1) || !checkURL(this.form.thesis_file2)) {
        callback(new Error('请上传2个正确论文地址'))
      }else{
        callback()
      }
    }
    const emptyRule = (rule, value, callback) => {
      if (!this.provinceId || !this.cityId ) {
        callback(new Error('请选择报名点省份和市区'))
      } else {
        callback()
      }
    }
    return {
      config,
      qntoken:'',
      form:{
        // name:'123',//姓名
        // birthdayData:1635782400000,
        // email:'1@163.com',//邮箱
        // telephone:'13757120491',//手机号
        // provinceId:'120000',//省
        // cityId:'120100',//市
        // districtId:'120101',//区
        // address:'123',//详细地址
        // school:'123',//学校
        // education:'3',//学历
        // diplomaImage1:'http://123.png',
        // diplomaImage2:'http://123.png',
        // inSchool:1,
        // ipType:this.$route.query.type ? Number(this.$route.query.type) : '1',
        // workYear:4,
        // course:1,
        // teachType:1,
        // thesis_file1:'',
        // thesis_file2:'',
        // idCard:'412829198906128037',
        // thesisFile:'123',
        // region:["120000", "120100", "120101"],
        // companyId:'',
        // code:'IGHQKP'
        name:'',//姓名
        birthdayData:null,
        email:'',//邮箱
        telephone:'',//手机号
        provinceId:0,//省
        cityId:0,//市
        districtId:0,//区
        address:'',//详细地址
        school:'',//学校
        education:'4',//学历
        diplomaImage1:'',
        diplomaImage2:'',
        inSchool:1,
        ipType:this.$route.query.type ? Number(this.$route.query.type) : '1',
        workYear:1,
        course:1,
        teachType:1,
        idCard:'',
        thesis_file1:'',
        thesis_file2:'',
        region:[],
        companyId:'',
        code:''
      },
      codeDisabled:false,
      paperShow:false,
      price:'',
      agreeChecked:false,
      active:1,
      regionData,
      educationList:[],//学历
      ipTypeCourseList:[],//课程选择
      maxIpType:4,
      workYearList:[],//工作年限
      provinceId:null,
      provinceList:[],//报名点省列表
      cityId:null,
      cityList:[],//报名点市列表
      areaList:[],
      companyList:[],
      inSchoolList:[
        { value:1, label:'社会人士' },
        { value:2, label:'在校学生' }
      ],
      teachTypeList:[
        { value:1, label:'线上' },
        { value:2, label:'线下' }
      ],
      areaList:[],
      pickerBeginDateBefore: {
				disabledDate(time) {
          let date = new Date();
          let year = date.getFullYear()-18;
          let day = date.getDate();
          let month = date.getMonth()+1;
					let timeSpace = time.getTime() > new Date(year+'-'+month+'-'+day);
					return timeSpace;
				}
			},
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        birthdayData: [
          { required: true, message: '请输入生日', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: ['change','blur'] }
        ],
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: phoneRules, trigger: ['change','blur'] }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: idCardValidity, trigger: ['change','blur'] }
        ],
        region: [
          { type:'array', required: true, message: '请选择活动区域'}
        ],
        address:[
          { required: true, message: '请输入您的详细地址', trigger: 'change' }
        ],
        school:[
          { required: true, message: '请选择学校', trigger: 'change' }
        ],
        education:[
          { required: true, message: '请选择学历', trigger: 'change' }
        ],
        inSchool:[
          { required: true, message: '请选择是否在校', trigger: 'change' }
        ],
        ipType:[
          { required: true, message: '请选择报名课程'}
        ],
        diplomaImage1:[
          { required: true, message: '请上传毕业证或学生证', trigger: 'change' }
        ],
        thesis_file1:[
          { required: true, message: '请上传论文'},
          { validator: fileRule}
        ],
        provinceId:[
          { validator: emptyRule}
        ],
        cityId:[
          { validator: emptyRule}
        ],
        companyId:[
          { required: true, message: '请选择报名点', trigger: 'change' }
        ],
      }
    };
  },
  created(){
    if(!getToken()){
      this.$router.push({name:'Login'}) 
    }
    let time = new Date();
    let year = time.getFullYear()-18;
    let day = time.getDate();
    let month = time.getMonth()+1;
    this.form.birthdayData=new Date(year+'-'+month+'-'+day).getTime()
    this.getQnToken();
    this.getCommonList();
    this.getAgentAreaList();
    this.editValidate();
    this.getMemberInfoByEcho();
  },
  mounted () {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack () {
        if(this.active>1){
          // console.log("点击了浏览器的返回按钮");
          this.$refs.form.clearValidate()
          this.active--
        }else{
          sessionStorage.clear();
          window.history.back();
          history.pushState(null, null, document.URL);
        }
        
    }, 
    getQnToken() {
			this.$store.dispatch("GetUploadToken").then((res) => {
				this.qntoken = res.data.qnToken;
			});
		},
    getAgentAreaList(){
      this.$http
      .get('/api/web/common/getAgentAreaList')
      .then(res=>{
        const {areaList}=res.data;
        this.areaList=areaList;
        this.provinceList=areaList.map((item,index)=>{return {value:item.area_code,label:item.area_name}})
        this.cityList=areaList[0].children.map((item,index)=>{return {value:item.area_code,label:item.area_name}})
        this.getCompanyList()
      })
    },

    selectEdu(){
      this.form.inSchool=1;
      this.editValidate()
    },
    selectInSch(){
      if(this.form.inSchool==2){
        this.form.workYear=1;
        this.price=this.ipTypeCourseList[0].schoolPrice;
      }else{
        this.price=this.ipTypeCourseList[0].price;
      }
      this.codeDisabled=false
    },
    checkCode(){
      this.changeType(this.form.ipType)
    },
    checkPromoCode(){
      const data={
        code:this.form.code,
        ipType:this.form.ipType
      }
      this.$http
      .post('/api/web/signUp/checkPromoCode',data)
      .then(res=>{
        this.codeDisabled=true;
        this.price=this.price - Number(res.data.amount)
      }).catch(err=>{
        this.$toast(err.msg)
      })
    },
    editValidate(){
      //1 社会，2在校
      //初中1，高中2，专科3，本科4，研究生5
      //1: "无"，2: "2年"，3: "5年"，4: "7年"
      const form=this.form;
      this.paperShow=false;
      if(form.education<3){
        this.maxIpType=1;
        console.log('高中学历')
      }else if(form.education==3){
        if(form.inSchool==1 && form.workYear==2){
          console.log('大专-2年')
          this.maxIpType=2;
        }else if(form.inSchool==1 && form.workYear==3){
          console.log('大专-5年')
          this.maxIpType=3;
        }else if(form.inSchool==1 && form.workYear==4){
          //显示论文提交
          console.log('大专-7年')
          this.maxIpType=4;
          this.paperShow=true;
        }
        else{
          console.log('大专-其他')
          this.maxIpType=1;
        }
      }else if(form.education==4){
        if(form.inSchool==1 && form.workYear==1){
          console.log('本科-0年')
          this.maxIpType=1;
        }else if(form.inSchool==1 && form.workYear==2){
          console.log('本科-2年')
          this.maxIpType=3;
        }else if(form.inSchool==1 && form.workYear>=3){
          //显示论文提交
          console.log('本科-5年')
          this.maxIpType=4;
          this.paperShow=true;
        }
        else{
          console.log('本科-其他')
          this.maxIpType=1;
        }
      }else{
        if(form.inSchool==1 && form.workYear==1){
          console.log('研究生-0年')
          this.maxIpType=3;
        }else if(form.inSchool==1 && form.workYear>=2){
          console.log('研究生-2年')
          this.maxIpType=4;
          this.paperShow=true;
        }
        else{
          console.log('研究生-其他')
          this.maxIpType=2;
        }
      }
      this.form.ipType=1
      this.changeType(this.form.ipType)
    },
    changeProvince(val){
      this.cityList=this.areaList
        .filter(item=>{
          return item.area_code==val
        })[0].children.map((item,index)=>{return {value:item.area_code,label:item.area_name}})
      this.cityId=this.cityList[0].value
      this.getCompanyList()
    },
    getCompanyList(){
      this.$http
      .get(`/api/web/common/getCompanyList?provinceId=${this.provinceId}&cityId=${this.cityId}`)
      .then(res=>{
        const { companyList }=res.data;
        this.companyList=companyList.map((item,index)=>{return {value:item.companyId,label:item.companyName}})
        if(this.companyList.length){
          this.form.companyId=this.companyList[0].value
        }else{
          this.form.companyId=null;
        }
      })
    },
    getCommonList(){
      this.$http
      .get('/api/web/common/getCommonList')
      .then(res=>{
        let { educationList,ipTypeCourseList,workYearList }=res.data;        
        this.educationList=Object.keys(educationList).map((item)=>{return {value:item,label:educationList[item]}});
        this.ipTypeCourseList=ipTypeCourseList;
        this.price=this.ipTypeCourseList[this.form.ipType-1].price;
        // console.log(this.ipTypeCourseList[this.form.ipType-1])
        this.workYearList=Object.keys(workYearList).map((item,index)=>{return {value:Number(item),label:workYearList[item]}});
        console.log(this.workYearList)
      })
    },
    changeType(val){
      if(this.form.inSchool==1){
        this.price=this.ipTypeCourseList[val-1].price
      }else{
        this.price=this.ipTypeCourseList[val-1].schoolPrice
      }
      this.codeDisabled=false
      // console.log(val)
    },
    changeCity() {
			this.form.provinceId=this.form.region[0];
			this.form.cityId=this.form.region[1];
			this.form.districtId=this.form.region[2];
		},
    beforeUpload(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return (isJPG || isPNG) && isLt5M;
    },
    uploadSuccess1(res){
			this.form.diplomaImage1=config.qnurl+res.key;
		},
    uploadSuccess2(res){
			this.form.diplomaImage2=config.qnurl+res.key;
		},
    //获取用户信息----报名、考试回显
    getMemberInfoByEcho(){
      this.$http
        .get("/api/web/my/getMemberInfoByEcho")
        .then((res) => {
          let newObj = res.data.memberInfo;
          this.form = Object.assign(this.form,newObj);
          console.log(this.form)
          this.form.birthdayData = this.form.birthday*1000; 
        });
    },
    onSubmit(){  
      this.$refs.form.validate((valid) => {
				if (valid) {
					this.form.birthday=this.form.birthdayData/1000;
          console.log(this.form)
          this.$http
          .post('/api/web/signUp/postAddSignUp',this.form)
          .then(res=>{
            // console.log(res)
          })
          .catch(err=>{
            if(err.status == 10000){
              this.$toast(err.msg)
            }else{
              document.querySelector('body').innerHTML = err
              document.forms[0].submit()
            }
            
          })
				} else {
					// this.$toast('请填写完整表单')
					return false;
				}
			});      
    },
    next() {
      // console.log(this.form)
      if(this.active == 2){
        if(!this.form.diplomaImage1 || !this.form.diplomaImage2){
          this.$toast('请上传完整毕业证或学生证！')
          return
        }
      }
      this.$refs.form.validate((valid) => {
				if (valid) {
          if (++this.active > 3) this.active = 0;
          console.log(this.active)
				} else {
					// this.$toast('请填写完整表单')
					return false;
				}
			});
    },
    onRoute(name,type) {
      let routeUrl = this.$router.resolve({
        name,
        query:{type}
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>

<style lang='less' scoped>
.enroll-content{
  width: 1300px;
  margin: 30px auto;
  background: #fff;
  padding: 30px 180px;
  .enroll-title{
    font-size: 24px;
    font-weight: 700;
    color: #333;
  }
  .region-item{
    .el-col-12{
      .el-form-item{
        margin-bottom: 20px;
      }
    }
  }
  /deep/.enroll-steps{
    padding: 35px 0 30px;
    .el-step__icon{
      width: 30px;
      height: 30px;
    }
    .el-step__line{
      top: 13px;
    }
    .el-step__icon-inner{
      font-size: 18px;
    }
    .el-step__title.is-process {
      font-weight: 500;
      color: #999;
    }
    .el-step__head.is-finish {
      color: #d71a18;
      border-color: #e7e7e7;
    }
    &.active3{
      .el-step{
        .is-finish {
          color: #d71a18;
          border-color: #d71a18;
        }
      }
    }
    &.active2{
      .el-step:first-child{
        .is-finish {
          color: #d71a18;
          border-color: #d71a18;
        }
      }
      .is-text{
        border-color: #d71a18;
      }
      .el-step__head.is-finish {
        .el-step__icon{
          color: #fff;
          background: #d71a18;    
        }
        .el-step__line-inner{
          border-width:0 !important;
        }
        .el-step__line{
          border-style:dashed;
          border-width: 1px;
          // border: 1px dashed #e7e7e7;
          background: none;
        }
      }
    }
    .el-step__head.is-finish {
      .el-step__icon{
        color: #fff;
        background: #d71a18;    
      }
      .el-step__line-inner{
        border-width:0;
      }
      .el-step__line{
        border-style:dashed;
        border-width: 1px;
        background: none;
      }
      .el-step__line-inner{
        border-width: 0 !important;
      }
    }
    .el-step__head.is-process {
      .el-step__icon{
        color: #fff;
        font-weight: 700;
        background: #e7e7e7;
        border-color:#e7e7e7 ;    
      }
      .el-step__line{
        border: 1px dashed #e7e7e7;
        background: none;
      }
    }
    .el-step__head.is-wait {
      .el-step__icon{
        color: #fff;
        font-weight: 700;
        background: #e7e7e7;
        border-color:#e7e7e7 ;    
      }
      .el-step__line{
        border: 1px dashed #e7e7e7;
        background: none;
      }
    }
  }
  /deep/.enroll-form{
    padding:30px 0;
    .el-form-item{
      margin-bottom: 30px;
    }
    .el-select,.el-cascader{
      width: 100%;
    }
    .el-form-item__label{
      width: 100%;
      line-height: 1;
      color: #333;
      font-size: 16px;
      height: 36px;
      padding-bottom: 20px;
    }
    .el-input__inner{
      height: 50px;
      font-size: 16px;
    }
    .el-date-editor{
      width: 100%;
    }
    .card-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 5px;
      .card-uploader {
        margin-right: 35px;
      }
      .card-uploader .el-upload{
        position: relative;
        width: 250px;
        height: 190px;
        border: 1px dotted #CCCCCC;
        border-radius: 5px;
        overflow: hidden;
        .avatar-uploader-icon {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 0;
          top: 60px;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.4);
          width: 100%;
          height: 100%;
          line-height: 90px;
          text-align: center;
          &::before{
            line-height: 1;
          }
          .desc{
            line-height: 1;
            height: 20px;
            font-size: 15px;
          }
        }
      }
      .card-img{
        width: 250px;
        height: 190px;
      }
      .tips{
        margin-left: auto;
        margin-right: 30px;
        color: #ccc;
        font-size: 14px;
      }
    }
    .discount-box{
      position: relative;
      padding-top: 40px;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -180px;
        width: 1300px;
        height: 1px;
        background: #f5f5f5;
      }
      .el-form-item{
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
      .el-form-item__label{
        width: auto;
        padding: 0 15px;
        line-height: 36px;
      }
      .el-form-item__content{
        display: flex;
        align-items: center;
      }
      .btn-discount{
        background: #d71a18;
        min-width: 100px;
        height: 40px;
        padding: 10px 20px;
        // line-height: 36px;
        margin-left: 10px;
        &.is-disabled{
          border-color: #eb8d8c;
          background: #eb8d8c;
        }
      }
    }
    .file-upload{
      .el-input-group__append{
        position: relative;
      }
      .file-uploader{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        .el-upload{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .money-box{
    display: flex;
    align-items: flex-end;
    margin-top: 40px;
    font-size: 16px;
    color: #333;
    .money{
      color: #dc3635;
      font-size: 30px;
      line-height: 0.8;
      .small{
        font-size: 16px;
        font-style: initial;
      }
    }
    .txt{
      font-size: 14px;
      color: #D71A18;
    }
  }
  .agreement{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 20px;
    font-size: 14px;
    color: #333;
    .el-checkbox{
      margin-right: 10px;
    }
    .link{
      color: #D71A18;
      cursor: pointer;
    }
  }
  .el-button{
    min-width: 160px;
    height: 50px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
  }
}
</style>
